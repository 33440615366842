/* modal.module.css */


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px); 
}

.modalContent {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); 
  max-width: 400px;
  width: 90% !important;
  animation: fadeIn 0.3s ease; 
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

input[type="number"] {
  width: 100%;
  padding: 3px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1em;
  outline: none;
  transition: border-color 0.3s ease;
}

input[type="number"]:focus {
  border-color: #4A90E2; 
}


button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}


button[type="submit"] {
  background-color: #4A90E2; 
  color: #ffffff;
}

button[type="submit"]:hover {
  background-color: #357ABD;
  transform: scale(1.05); 
}


button[type="button"] {
  background-color: #CCCCCC; 
  color: #333333;
}

button[type="button"]:hover {
  background-color: #AAAAAA; 
  transform: scale(1.05); 
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
