.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin-top: 50px;
  width: auto;
  height: 100%;
  overflow-y: auto;
}


/* .restArea */
.restArea {
  width: auto;
  min-height: 100px; /* Use min-height instead of height */
}

.intro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: center;
  align-items: center;
  font-size: 50px;
}

.heading {
  font-weight: 700;
  font-size: 2.5rem;
  color: #eeeeee;
  margin-bottom: 20px;
}

.paragraph {
  font-family: 'Poppins', sans-serif;
  color: #a9a9a9;
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.gridContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  /* background-color: antiquewhite; */
}


/* Feature Card */
.featureCard {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(97, 97, 97, 0.12)  !important; 
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.042)  !important;
  backdrop-filter: calc(20);
  color: #fff !important ;
}

.featureCard:hover {
  background-color:rgba(238, 238, 238, 0.3);
  backdrop-filter: calc(10);
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Feature Card Content */
.featureCardContent {
  color: #fff !important ;
  text-align: center;
  padding: 20px;
}

.featureTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: #93d1e3;
  margin-bottom: 10px;
}

.featureCardDesc{
  color: #e2e2e2;
}
/* Expand More Icon */
.expandMoreIcon {
  font-size: 1.5rem;
  color: #afd6ff;
  margin-top: 10px;
}


.gridContainer2 {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 10px;
  padding-left: 150px;
  padding-right: 150px;
}

.contributors {
  margin-top: 50px;
  text-align: center;
  height: auto;
}

.contributorsHeading {
  font-weight: 700;
  font-size: 2rem;
  color: #dbdbdb;
  margin-bottom: 20px;
}

.contributorCard {
  background-color: transparent;
  color: rgb(4, 4, 4);
  width: auto;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 50px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.contributorCard:hover {
  transform: translateY(-10px);
}

.contributorCardContent {
  text-align: center;
  padding: 15px;
}

.contributorName {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px); 
  color: #fff;
  padding: 8px 16px;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
  letter-spacing: 0.5px;
  opacity: 0;
  transition: opacity 0.4s ease, transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); 
}

.contributorCard:hover .contributorName {
  opacity: 1;
  transform: translate(-50%, -10px); 
}
.contributorRole {
  font-family: 'Poppins', sans-serif;
  color: #666;
  font-size: 1rem;
}

.contributorImage {
  width: 80px; 
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.githubLink {
  color: #007bff; 
  text-decoration: none;
  margin-top: 10px;
}

.githubLink:hover {
  text-decoration: underline;
}


/* Hide Scrollbar */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.footer{
  min-height: 150px;
  color: #c5c5c5 ;
}


.technologiesSection {
  margin-top: 40px;
  text-align: center;
}

.technologiesSection {
  margin-top: 40px;
  text-align: center;
}

.technologiesSectionHeading {
  font-weight: 700;
  font-size: 2rem; /* Adjusted size */
  color: #f8f8f8; /* Matches "Meet Our Contributors" color */
  margin-bottom: 20px;
}

.techCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px; /* Smaller width */
  height: 60px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.techCard:hover {
  transform: translateY(-10px);
}

.techIcon {
  width: 90%; /* Slightly smaller icon */
  height: 90%;
  border-radius: 50%; /* Optional for rounded icons */
  object-fit: cover;
}

/* Position the name slightly higher */
.techName {
  position: absolute;
  top: 40%; /* Positioned a bit higher on the icon */
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  color: #fff;
  padding: 4px 8px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.7rem;
  text-align: center;
  letter-spacing: 0.5px;
  opacity: 0;
  transition: opacity 0.4s ease;
  width: 80%;
  text-align: center;
}

.techCard:hover .techName {
  opacity: 1;
}
