.HomePageContent {
    overflow: auto;
    background-color: #121212;
    color: #e0e0e0;
    transition: background-color 0.5s ease;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    background-color: black;
    transition: all 0.3s ease;
}

.leftColumn {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #181818;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

.profilePic {
    width: 100%;
    max-width: 120px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 3px solid #4a90e2;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profilePic:hover {
    transform: scale(1.1);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.6);
}

.createPostBtn {
    background: linear-gradient(135deg, #4a90e2, #357ab8);
    color: #fff;
    border-radius: 25px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.createPostBtn:hover {
    background: linear-gradient(135deg, #357ab8, #4a90e2);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
}

.createPostBtn:active {
    background: linear-gradient(135deg, #4a90e2, #357ab8);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
}

.middleColumn {
    flex: 3;
    background-color: #1f1f1f;
    padding: 20px;
    overflow-y: auto;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
}

.post-card {
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #e0e0e0;
}

.avatar-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.owner-name {
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    transition: color 0.3s ease;
}

.owner-name:hover {
    color: #4a90e2;
}

.job-desc {
    cursor: pointer;
}

.job-desc:hover {
    background-color: #333;
    transition: background-color 0.3s ease;
}

.middleColumn::-webkit-scrollbar {
    width: 8px;
}

.middleColumn::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
}

.middleColumn::-webkit-scrollbar-track {
    background: #1f1f1f;
}

.rightColumn {
    flex: 1.5;
    padding: 20px;
    overflow-y: auto;
    background-color: #1e1e1e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    box-sizing: border-box;
}

/* New styles for the right column (job listings) */
.rightColumn::-webkit-scrollbar {
    width: 8px;
}

.rightColumn::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
}

.rightColumn::-webkit-scrollbar-track {
    background: #1e1e1e;
}

.jobform-page {
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.job-forms-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
}

.jobform-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.job-form-box {
    background-color: #2c2c2c;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    border: 1px solid #444;
    overflow: hidden;
}

.job-form-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.job-form-box .avatar-section {
    padding: 15px;
    background-color: #333;
    border-bottom: 1px solid #444;
}

.job-form-box .job-desc {
    padding: 15px;
}

.job-role, .job-location, .job-company {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.job-role svg, .job-location svg, .job-company svg {
    margin-right: 10px;
    color: #4a90e2;
}

.job-texts {
    font-size: 14px;
    color: #e0e0e0;
}

.job-skill {
    color: #4a90e2;
    font-weight: 500;
}

.no-jobs-available {
    text-align: center;
    color: #888;
    padding: 20px;
    border: 1px dashed #444;
    border-radius: 10px;
}

.job-form-footer {
    margin-top: 20px;
    text-align: center;
    border-top: 1px solid #444;
    padding-top: 20px;
}

.view-all-jobs {
    background: linear-gradient(135deg, #4a90e2, #357ab8);
    color: #fff;
    border-radius: 25px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.view-all-jobs:hover {
    background: linear-gradient(135deg, #357ab8, #4a90e2);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
}

.post-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    background-color: #333;
}

.post-title {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
    transition: font-size 0.3s ease;
}

.post-title:hover {
    font-size: 24px;
}

.post-description {
    font-size: 16px;
    color: #b0b0b0;
    line-height: 1.6;
    transition: color 0.3s ease;
}

.post-description:hover {
    color: #ffffff;
}

.post-image {
    width: 100%;
    border-radius: 5px;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.post-image:hover {
    opacity: 0.85;
    transform: scale(1.05);
}

.post-tags {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    transition: all 0.3s ease;
}

.post-tag {
    background-color: #007bff;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.post-tag:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .leftColumn, .rightColumn {
        width: 100%;
        max-width: 100%;
    }

    .middleColumn {
        width: 100%;
        grid-template-columns: 1fr;
    }
}