.create-form-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #1a1a1a;
}
.create-form-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #e0e0e0;
}
.create-role-loc-form, 
.create-form-select, 
.create-desc-form-textarea, 
.create-form-content input, 
.create-form-content select, 
.create-form-content textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 14px;
    font-size: 16px;
    border: 1px solid #444;
    border-radius: 6px;
    background-color: #3a3a3a;
    color: #e0e0e0;
    margin-bottom: 20px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.create-role-loc-form:focus, 
.create-form-select:focus, 
.create-desc-form-textarea:focus, 
.create-form-content input:focus, 
.create-form-content select:focus, 
.create-form-content textarea:focus {
    border-color: #66b2ff;
    box-shadow: 0 0 5px rgba(102, 178, 255, 0.5);
    outline: none;
}
.create-desc-form-textarea {
    height: 180px;
    resize: vertical;
}
.create-button-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    gap: 15px;
}
.create-button, 
.create-preview-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}
.create-button:hover, 
.create-preview-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 91, 187, 0.3);
}
.create-preview-container {
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    color: #e0e0e0;
    margin-top: 30px;
}
.create-preview-heading {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
}
.create-preview-field {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #444;
}
.create-preview-label {
    font-weight: 600;
    color: #e0e0e0;
}
.create-preview-value {
    color: #b0c4de;
    font-size: 16px;
    margin-top: 5px;
}
.create-requiredskills-flex {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}
.create-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.create-button {
    display: block;
    margin: 0 auto;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 30px;
}
.create-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 91, 187, 0.3);
}

.create-skills-form-control {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #3a3a3a;
    border: 1px solid #444;
    border-radius: 6px;
    /* padding: 10px; */
}

.create-role-loc-form::placeholder,
.create-form-select::placeholder,
.create-desc-form-textarea::placeholder,
.create-form-content input::placeholder,
.create-form-content select::placeholder,
.create-form-content textarea::placeholder {
    color: #ffffff;
    opacity: 0.7;
}

.create-remove-skill-button {
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
    color: #ff4d4d;
}

.create-remove-skill-button:hover {
    color: #ff1a1a;
    transform: scale(1.1);
}
