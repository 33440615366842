/* Main Layout */
.formbyidPage {
  width: 100%;
  height: 100vh;
  display: flex;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #e0e0e0;
  gap: 1rem;
  padding: 1rem;
  overflow: hidden; /* Prevents the entire page from scrolling */
}

/* Scrollbar Styles */
.formbyidPage *::-webkit-scrollbar {
  width: 8px;
}

.formbyidPage *::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.formbyidPage *::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.formbyidPage *::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Avatar Section - First Column */
.avatarSection {
  width: 300px;
  min-width: 250px;
  height: auto;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow-y: auto;
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.avatarSection:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
}

.avatar {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  transition: transform 0.3s ease;
  font-size: 1.2rem;
  font-weight: 600;
  color: #e0e0e0;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}


.avatar:hover {
  transform: scale(1.05);
}

.ownerName {

  font-size: 1.5rem;
  font-weight: 600;
  color: #e0e0e0;
  text-align: center;
  margin-top: 1rem;

}

.ownerEmail {
  font-size: 0.9rem;
  color: rgba(69, 249, 255, 0.7);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  min-width: 260px;
  width: auto;
  transition: all 0.3s ease;
}

.ownerEmail:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

/* Job Description Section - Second Column */
.jobDescSection {
  flex: 1;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  height: 100vh;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

/* Upper fixed part of job description */
.jobHead {
  position: relative;
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(219, 255, 157, 0.855);
  border-radius: 8px;
  font-size: 1.8rem;
  font-weight: 600;
  transition: all 0.3s ease;
  height: auto;
  flex-shrink: 0; /* Prevent shrinking */
}

/* Scrollable part of job description */
.jobBody {
  flex: 1; /* Takes the remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}


.jobDescription {
  gap: 0.75rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  height: auto;
}

.jobDescTexts {
  margin-top: 10px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.03);
  border: none;
  border-radius: 6px;
  padding: 1rem;
  color: rgba(224, 224, 224, 0.9);
  font-size: 1rem;
  line-height: 1.6;
  resize: none;
  transition: all 0.3s ease;
  height: 100vh;
}

  .jobDescSection:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
  }
  
  .jobRole {


    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    font-size: 1.8rem;
    font-weight: 600;
    transition: all 0.3s ease;
    height: auto;

  }


 
  /* .jobRole:hover {
    background-color: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
  } */
  
  .jobMislanious {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.3s ease;
  }
  
  .jobMislanious:hover {
    background-color: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
  }
  
  .jobMislanious ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .jobMislanious li {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: rgba(224, 224, 224, 0.9);
  }
  
  /* Skills Section */
  .jobSkills {
    
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 1rem 3rem;
    transition: all 0.3s ease;
  }
  
  .jobSkills:hover {
    background-color: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
  }
  
  .skillsContainer {
    display: flex;
    gap: 0.75rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0.5rem 0;
    scroll-behavior: smooth;
  }
  
  .skillsContainer::-webkit-scrollbar {
    display: none;
  }
  
  .jobSkill {
    background-color: rgba(255, 255, 255, 0.1);
    color: #e0e0e0;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
  
  /* .jobSkill:hover {
    transform: translateY(-2px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.15);
  } */
  
  .arrowLeft, .arrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .arrowLeft {
    left: 0.5rem;
  }
  
  .arrowRight {
    right: 0.5rem;
  }
  
  .arrowLeft:hover, .arrowRight:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-50%) scale(1.1);
  }
  

  
  /* .jobDescription:hover {
    background-color: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
  } */
  
  .jobDescHeading {
    font-size: 1.2rem;
    font-weight: 600;
    overflow-x:hidden;
    margin-bottom: 3rem;
  }
  
  .jobDescTexts:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  /* Applicants Section */
  .applicantsSection {
    width: 300px;
    min-width: 250px;
    max-height: 100vh;
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  }
  
  .applicantsSection:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
  }
  
  .applicantsSection h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #00b9bf;
  }
  
  .applicantAvatarSection {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    margin-left: -15px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .applicantAvatarSection:hover {
    transform: translateX(4px);
    background-color: rgba(255, 255, 255, 0.08);
  }
  
  .applicantAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    transition: all 0.3s ease;
  }
  
  .applicantAvatarSection:hover .applicantAvatar {
    transform: scale(1.1);
  }
  
  .applicantOwnerName {
    font-size: 1rem;
    flex-grow: 1;
  }
  
  /* Buttons */
  /* General Button Styles */
#change-btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Apply Button */
.applyButton {
 
    background: linear-gradient(135deg, #4a90e2, #357ab8);
    color: #fff;
    border-radius: 25px;

}

.applyButton:hover {
    background: linear-gradient(135deg, #5a9fe2, #468db8);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Shortlist Button */
.shortlistSection button {
    background-color: #28a745; /* Green */
    color: #fff;
    border-radius: 25px;
   
}

.shortlistSection button:hover {
    background-color: #218838; /* Darker Green */
}

/* Interview Button */
.interviewSection button {
    background-color: #dca80b; /* Yellow */
    color: #ffffff;
    border-radius: 25px;
    font-size: medium;
}

.interviewSection button:hover {
    background-color: #b68900; /* Darker Yellow */
}

  
  .shortlistSection {
    margin-top: 2rem;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .shortlistSection:hover {
    background-color: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
  }
  
  .shortlistSection input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.08);
    color: #e0e0e0;
    transition: all 0.3s ease;
    flex: 1;
    overflow-y: auto; 
  }
  
  .shortlistSection input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  /* .shortlistSection button {
    background: linear-gradient(135deg, #2c2c2c 0%, #1a1a1a 100%);
    color: #e0e0e0;
  }
  
  .shortlistSection button:hover {
    background: linear-gradient(135deg, #3a3a3a 0%, #282828 100%);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  } */
  
  .interviewSection {
    margin-top: 1rem;
  }
  
  /* .interviewSection button {
    width: 100%;
    background: linear-gradient(135deg, #2c2c2c 0%, #1a1a1a 100%);
    color: #e0e0e0;
  }
  
  .interviewSection button:hover {
    background: linear-gradient(135deg, #3a3a3a 0%, #282828 100%);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  } */

  .jobDescSection{
    max-height: 85vh; /* Example height to limit and allow scrolling */
    overflow-y: auto;  /* Enable scrolling within these sections */
}
.applicantsSection {
  max-height: 85vh; 
  overflow-y:hidden; 
}
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .formbyidPage > * {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .avatarSection { animation-delay: 0.1s; }
  .jobDescSection { animation-delay: 0.2s; }
  .applicantsSection { animation-delay: 0.3s; }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .formbyidPage {
      padding: 0.5rem;
      gap: 0.5rem;
      flex-direction: column;
      overflow-y: auto;
    }
    
    .avatarSection,
    .applicantsSection {
      width: 250px;
      min-width: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .formbyidPage {
      flex-direction: column;
      height: auto;
      overflow-y: auto;
    }
    
    .avatarSection,
    .jobDescSection,
    .applicantsSection {
      width: 100%;
      height: auto;
      max-height: none;
    }
    
    .avatar {
      width: 120px;
      height: 120px;
    }
    
    .shortlistSection {
      flex-direction: row;
    }
    
    .shortlistSection input {
      flex-grow: 1;
    }
  
    .applyButton {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      z-index: 100;
    }
  }

  #job-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  #job-details {
    width: 60%;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  #job-description {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    max-height: 400px;
  }
  
  #apply-button {
    position: sticky;
    bottom: 0;
    background: #fff;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #ddd;
}



.parent {
  display: flex;
  width: 100%;
  height: 100vh; /* Full viewport height */
}

.left-column, .right-column {
  flex: 1; /* Set equal width for left and right columns */
  background-color: lightblue;
  padding: 1rem;
}

.middlecolumn {
  flex: 2; /* Middle column will take twice the space of the others */
  display: flex;
  flex-direction: column;
}

.upperscrollable {
  flex: 1; /* Scrollable area will expand to fill available space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 1rem;
}

.lowerfixed {
  
  height: 60px;
  padding: 1rem;
}
.noappl{
  text-align: center;
}