.home-screen {
  background-image: linear-gradient(135deg, #000e1a 0%, #5b6c78 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(22, 30, 65);
}

/* .content {
  text-align: center;
} */

h1 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  color: #555;
} 
