/* shortlistedApplicants.module.css */

.shortlistedPage {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .applicantItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .applicantItem span {
    font-size: 18px;
    color: #555;
  }
  
  .applicantItem:hover {
    background-color: #f1f1f1;
  }
  