.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it overlays other content */
}

.modal-content {
    background-color: #121212; /* Dark background for the modal */
    border-radius: 10px;
    padding: 40px;
    max-width: 500px; /* Width of the modal */
    width: 90%; /* Responsive width */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    
}
h3{
    margin-top: -10px;
}
h4{
    margin-top: -40x;
    padding-top: -30px;
    color: #e0e0e07e;
}
.close-button {
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 22px;
    font-size: 24px; /* Adjust size as needed */
    color: #e0e0e0; /* Close button color */
}

.close-button:hover {
    color: #ff4081; /* Change color on hover for a nice effect */
}

/* Additional styles for the CreatePostTextSection can go here if needed */
